<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="mb-7">
          <v-card-text class="pa-5">
            <div class="d-sm-flex align-center">
              <div>
                <h2 class="font-weight-regular title">Información de inscripción</h2>
                <h6 class="body-2 mb-1 subtitle-2 font-weight-light">Datos de inscritos</h6>
              </div>
            </div>
          </v-card-text>
          <v-card-text class="pa-5 border-bottom">
            <TheInscriptionForm
                :saveLoading="saveLoading"
                @validated="doCreateInscription($event)"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Formulario",
  components: {
    TheInscriptionForm: () =>
        import(
            "@/components/dashboardComponents/TheInscriptionForm"
            ),
  },
  data: () => ({
    saveLoading: false,
    page: {
      title: "Formulario"
    },
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        to: "#"
      },
      {
        text: "Formularios",
        disabled: false,
        href: "/dashboard/inscriptions/"
      },
      {
        text: "Crear formulario",
        disabled: true
      },
    ],
    inscription: null,
  }),
  methods: {
    async fetchInscription() {
      const inscriptionId = this.$route.params.id
      const inscriptionResponse = await this.$http.get(`api/admin/matriculas/formularios/${inscriptionId}/`)
      this.inscription = inscriptionResponse.data
    },
    async createInscription(inscription) {
      const payload = new FormData()
      payload.append('nombres', inscription.nombres)
      payload.append('apellidos', inscription.apellidos)
      payload.append('documento', inscription.documento)
      payload.append('email', inscription.email)
      payload.append('comentario', inscription.comentario)
      payload.append('carnet_cmp', inscription.carnet_cmp)
      payload.append('autoriza_foto', inscription.autoriza_foto)
      payload.append('fecha_nacimiento', inscription.fecha_nacimiento)
      payload.append('region', inscription.region)
      payload.append('celular', inscription.celular)
      payload.append('seccion_id', inscription.seccion.id)
      payload.append('institucion_id', inscription.institucion.id)
      payload.append('otro_canal_referencia', inscription.otro_canal_referencia)
      inscription.canales_referencia.forEach((canalReferencia) => {
        payload.append('canales_referencia_ids', canalReferencia.id)
      })
      inscription.newFiles.forEach((newFile, i) => {
        payload.append(`vouchers[${i}]imagen`, newFile.image)
      })
      if (typeof inscription.foto_dni !== 'string') {
        payload.append('foto_dni', inscription.foto_dni)
      }
      const inscriptionResponse = await this.$http.post(`api/admin/matriculas/formularios/`, payload)
      this.inscription = inscriptionResponse.data
    },
    async doCreateInscription(event) {
      try {
        this.saveLoading = true
        await this.createInscription(event)
        const confirm = await this.$root.$confirm({
          title: 'Mensaje',
          message: 'Inscripción creada.'
        })
        if (confirm) {
          await this.$router.push({ name: 'UpdateInscription',  params: { id: this.inscription.id }})
        }
        this.saveLoading = false
        // await this.$router.push({name: 'Inscriptions'})
      } catch (e) {
        this.saveLoading = false
        await this.$root.$confirm({
          title: '¡Ups!',
          message: 'Ha ocurrido un error inesperado. Inténtelo de nuevo en unos minutos.'
        })
      }
    },
  },
}
</script>

<style lang="scss">
.close-button:hover {
  opacity: 0.8%;
}

.upload-button {
  cursor: pointer;
  height: 50px;
  width: 250px;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 10px;
}

.voucher-image {
  width: 100%;
}

.card-vouchers {
  border: 1px solid gray !important;
}
</style>
